import { plainToInstance } from 'class-transformer';
import { map, throwError } from 'rxjs';
import { LocationGroup } from '../../models/LocationGroup.js';
import { ApiService } from './api-base.js';
const createLocationGroupParams = (locationGroup) => {
    const body = {
        location_group: {
            name: locationGroup.name,
            parent_id: locationGroup.parent_id,
            schedule_id: locationGroup.schedule_id,
            order: locationGroup.order
        }
    };
    return body;
};
export const LocationGroupService = {
    getLocationGroups: (projectId, parentId, scheduleId) => {
        let queryParams = '';
        if (parentId !== undefined && parentId !== null) {
            queryParams += `parent_id=${parentId}`;
        }
        if (scheduleId !== undefined && scheduleId !== null) {
            queryParams += ((queryParams.length > 0 ? '&' : '') + `schedule_id=${scheduleId}`);
        }
        return ApiService.get(`/projects/${projectId}/location_groups${queryParams.length > 0 ? `?${queryParams}` : ''}`)
            .pipe(map((response) => plainToInstance(LocationGroup, response)));
    },
    getLocationGroup: (projectId, locationGroupId) => ApiService.get(`/projects/${projectId}/location_groups/${locationGroupId}`)
        .pipe(map((response) => plainToInstance(LocationGroup, response))),
    getLocationGroupActivitiesCount: (projectId, locationGroupId) => ApiService.get(`/projects/${projectId}/location_groups/${locationGroupId}/activities_count`)
        .pipe(map((response) => response.count)),
    createLocationGroup: (projectId, locationGroup) => ApiService.post(`/projects/${projectId}/location_groups`, createLocationGroupParams(locationGroup))
        .pipe(map((response) => plainToInstance(LocationGroup, response))),
    updateLocationGroup: (projectId, locationGroupId, locationGroup) => ApiService.put(`/projects/${projectId}/location_groups/${locationGroupId}`, createLocationGroupParams(locationGroup))
        .pipe(map((response) => plainToInstance(LocationGroup, response))),
    deleteLocationGroupAdmin: (projectId, locationGroupId) => ApiService.delete(`/projects/${projectId}/location_groups/${locationGroupId}/admin`),
    moveLocationGroupBack: (projectId, locationGroupId, locationGroup) => {
        if (locationGroup.parent_id === undefined) {
            return throwError(() => 'Error: Cannot move location back. No parent location found.');
        }
        const body = Object.assign(Object.assign({}, locationGroup), { parent_id: locationGroup.grand_parent_id || undefined });
        return LocationGroupService.updateLocationGroup(projectId, locationGroupId, body);
    },
    moveLocationGroupForward: (projectId, newParentId, locationGroupId, locationGroup) => {
        const body = Object.assign(Object.assign({}, locationGroup), { parent_id: newParentId });
        return LocationGroupService.updateLocationGroup(projectId, locationGroupId, body);
    },
    moveLocationGroupUp: (projectId, locationGroupId, locationGroup) => {
        if (locationGroup.order === 0) {
            return throwError(() => 'Error: Cannot move location up. Location already at the top.');
        }
        const body = Object.assign({}, locationGroup);
        body.order -= 1;
        return LocationGroupService.updateLocationGroup(projectId, locationGroupId, body);
    },
    moveLocationDown: (projectId, maxIndex, locationGroupId, locationGroup) => {
        if (locationGroup.order === maxIndex) {
            return throwError(() => 'Error: Cannot move location down. Location already at the bottom.');
        }
        const body = Object.assign({}, locationGroup);
        body.order += 1;
        return LocationGroupService.updateLocationGroup(projectId, locationGroupId, body);
    }
};

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from "react";
import AppFormControl from '../../global/form-control/AppFormControl';
import AppErrorMessage from '../../global/error-message/AppErrorMessage';
import { CompanyLite, CompanyService, ProjectRole, ProjectUser, ProjectUserService, UserLite, UserService, UploadProgress } from '@lookahead/core';
import Alert from 'react-bootstrap/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useProject } from '../../global/layouts/project/ProjectLayout';
import { Observable } from 'rxjs';

const CreateEditProjectUserPage = () => {
  const { userId } = useParams();
  const [isNewUser, setIsNewUser] = useState(true);
  const [companyOptions, setCompanyOptions] = useState<CompanyLite[]>([]);
  const [companiesLoading, setCompaniesLoading] = useState(false);
  const [userOptions, setUserOptions] = useState<UserLite[]>([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    project_role: Yup.string().required('Project Role is required')
                    .oneOf([ProjectRole.ADMIN, ProjectRole.MANAGER, ProjectRole.FOREMAN, ProjectRole.TRADE_CONTRACTOR])
                    .default(ProjectRole.TRADE_CONTRACTOR),
    company: Yup.object({
      id: Yup.number().required(),
      name: Yup.string().required()
    }),
    send_invite: Yup.boolean().default(true),
    user: Yup.object({
      first_name: Yup.string()
                  .required('First Name is required'),
      last_name: Yup.string()
                    .required('First Name is required'),
      position: Yup.string().optional().nullable(),
      email: Yup.string().optional().nullable(),
      phone: Yup.string().required('Phone Number is required')
    })
  });
  const formOptions = { resolver: yupResolver(validationSchema), defaultValues: {project_role: ProjectRole.TRADE_CONTRACTOR, send_invite: false} };
  const { register, handleSubmit, formState, reset, getValues, setValue } = useForm(formOptions);
  const { errors } = formState;
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const isEdit = () => userId !== undefined;
  const { project } = useProject();

  const onSearchCompanies = (query: string) => {
    setCompaniesLoading(true);
    CompanyService.getCompanies(project.id, query).subscribe({
      next: companies => {
        setCompanyOptions(companies);
        setCompaniesLoading(false);
      },
      error: _ => {
        setCompanyOptions([]);
        setCompaniesLoading(false);
      }
    });
  };

  const onSearchUsers = (query: string) => {
    setUsersLoading(true);
    UserService.getUsers(query).subscribe({
      next: users => {
        setUserOptions(users);
        setUsersLoading(false);
      },
      error: _ => {
        setUserOptions([]);
        setUsersLoading(false);
      }
    });
  };

  const onSubmit = (data: any) => {
    if (isSubmitting) {
      return;
    }
    setErrorMessage(undefined);
    setIsSubmitting(true);
    const newProjectUser = new ProjectUser();
    newProjectUser.user = data.user;
    newProjectUser.project_role = data.project_role;
    newProjectUser.company = data.company;

    let request: Observable<UploadProgress<any>> = ProjectUserService.createProjectUserAdmin(project.id, newProjectUser, data.send_invite);
    if (isEdit()) {
      request = ProjectUserService.updateProjectUserAdmin(project.id, Number.parseInt(userId), newProjectUser)
    }
    request.subscribe({
      next: (progress) => {
        if (!progress.response) {
          return;
        }
        setIsSubmitting(false);
        navigate(`/projects/${project.id}/project-users`, {replace: true});
      },
      error: e => {
        setErrorMessage('Save Failed: Unable to save user changes.');
        setIsSubmitting(false);
      }
    });
  };

  useEffect(() => {
    if (!userId) {
      return;
    }
    setErrorMessage(undefined);
    ProjectUserService.getProjectUserAdmin(project.id, Number.parseInt(userId)).subscribe({
      next: projectUser => {
        reset({
          user: projectUser.user,
          project_role: projectUser.project_role,
          company: projectUser.company
        });
      }
    });
  }, [project, userId, reset]);

  return (
    <Container style={{marginTop: 16}}>
      <h2>{isEdit() ? 'Edit' : 'New'} Project User</h2>
      {errorMessage !== undefined && <Alert variant='danger'>{errorMessage}</Alert>}
      {!errorMessage?.includes('404') &&
      <Form onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))} style={{marginBottom: 48}}>
        {!isEdit() && isNewUser !== undefined &&
        <Form.Group className="mb-3" controlId="new-user">
          <Form.Check name="new-user" checked={isNewUser} onChange={() => setIsNewUser(true)} type='radio' id='new-user' label='New User' />
          <Form.Check name="new-user" checked={!isNewUser} type='radio' id='existing-user' onChange={() => setIsNewUser(false)} label='Existing User' />
        </Form.Group>
        }
        {(isEdit() || isNewUser) &&
        <>
        <Form.Group className="mb-3" controlId="first_name">
          <Form.Label>First Name</Form.Label>
          <AppFormControl {...register('user.first_name')} placeholder="" error={errors.first_name || undefined} />
          <AppErrorMessage name='user.first_name' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="last_name">
          <Form.Label>Last Name</Form.Label>
          <AppFormControl {...register('user.last_name')} placeholder="" error={errors.last_name || undefined} />
          <AppErrorMessage name='user.last_name' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="position">
          <Form.Label>Position</Form.Label>
          <AppFormControl {...register('user.position')} placeholder="" error={errors.position || undefined} />
          <AppErrorMessage name='user.position' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="phone">
          <Form.Label>Phone Number</Form.Label>
          <AppFormControl {...register('user.phone')} placeholder="" error={errors.phone || undefined} />
          <AppErrorMessage name='user.phone' errors={errors}/>
          <p>Used for user login. Must be unique.</p>
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <AppFormControl {...register('user.email')} placeholder="" error={errors.email || undefined} />
          <AppErrorMessage name='user.email' errors={errors}/>
        </Form.Group>
        {isNewUser && !isEdit() &&
        <Form.Group className="mb-3" controlId="role">
          <Form.Check {...register('send_invite')} id="send_invite" label='Send Invitation Text' />
        </Form.Group>
        }
        </>
        }
        {!isEdit() && !isNewUser &&
        <Form.Group className="mb-3">
          <Form.Label>User</Form.Label>
          <AsyncTypeahead
            id="user-typeahead"
            isLoading={usersLoading}
            labelKey={(o: any) => `${o.first_name} ${o.last_name}`}
            placeholder={getValues('user.name')}
            onChange={(c: UserLite[]) => c.length === 1 ? setValue('user', {...c[0]}) : setValue('user', {})}
            onSearch={(query) => onSearchUsers(query)}
            options={userOptions}
          />
        </Form.Group>
        }
        <Form.Group className="mb-3">
          <Form.Label>Company</Form.Label>
          <AsyncTypeahead
            id="company-typeahead"
            isLoading={companiesLoading}
            labelKey="name"
            placeholder={getValues('company.name')}
            onChange={(c: CompanyLite[]) => c.length === 1 ? setValue('company', {id: c[0].id, name: c[0].name}) : setValue('company', {id: undefined, name: undefined})}
            onSearch={(query) => onSearchCompanies(query)}
            options={companyOptions}
          />
          <AppErrorMessage name='company' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="role">
          <Form.Label>Project Role</Form.Label>
          <Form.Check {...register('project_role')} id="a-role" value={ProjectRole.ADMIN} type="radio" label='Admin' />
          <Form.Check {...register('project_role')} id="m-role"value={ProjectRole.MANAGER} type="radio" label='Manager'/>
          <Form.Check {...register('project_role')} id="f-role"value={ProjectRole.FOREMAN} type="radio" label='Foreman' />
          <Form.Check {...register('project_role')} id="t-role" value={ProjectRole.TRADE_CONTRACTOR} type="radio" label='Trade Contractor' />
          <AppErrorMessage name='project_role' errors={errors}/>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      }
    </Container>
  );
};
export default CreateEditProjectUserPage;
import { plainToInstance } from 'class-transformer';
import { map } from 'rxjs';
import { ProjectUser, AuthProjectUser } from '../../models/ProjectUser.js';
import { ApiService } from './api-base.js';
const createProjectUserParams = (projectUser, send_invite) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const body = {
        project_user: {
            project_role: projectUser.project_role,
            company: (_a = projectUser.company) === null || _a === void 0 ? void 0 : _a.id,
            user: {
                phone: (_b = projectUser.user) === null || _b === void 0 ? void 0 : _b.phone,
                email: (_c = projectUser.user) === null || _c === void 0 ? void 0 : _c.email,
                first_name: (_d = projectUser.user) === null || _d === void 0 ? void 0 : _d.first_name,
                last_name: (_e = projectUser.user) === null || _e === void 0 ? void 0 : _e.last_name,
                position: (_f = projectUser.user) === null || _f === void 0 ? void 0 : _f.position,
                profile_picture: (_g = projectUser.user) === null || _g === void 0 ? void 0 : _g.profile_picture_upload_file
            }
        },
        send_invite
    };
    // this is returning 'undefined'? TODO fix this
    if (!body.project_user.user.profile_picture) {
        delete body.project_user.profile_picture;
    }
    return body;
};
export const ProjectUserService = {
    getProjectUsers: (projectId, companyId, query) => {
        const url = ApiService.appendQueryParams(`/projects/${projectId}/project_users`, { company: companyId });
        return ApiService.getWithQuery(url, query)
            .pipe(map((response) => plainToInstance(ProjectUser, response)));
    },
    getProjectUsersAdmin: (projectId, companyId, query) => {
        const url = ApiService.appendQueryParams(`/projects/${projectId}/admin/project_users`, { company: companyId });
        return ApiService.getWithQuery(url, query)
            .pipe(map((response) => plainToInstance(ProjectUser, response)));
    },
    getAuthProjectUser: (projectId) => ApiService.get(`/projects/${projectId}/auth_project_user`)
        .pipe(map((response) => plainToInstance(AuthProjectUser, response))),
    getProjectUser: (projectId, userId) => ApiService.get(`/projects/${projectId}/project_users/${userId}`)
        .pipe(map((response) => plainToInstance(ProjectUser, response))),
    getProjectUserAdmin: (projectId, userId) => ApiService.get(`/projects/${projectId}/admin/project_users/${userId}`)
        .pipe(map((response) => plainToInstance(ProjectUser, response))),
    createProjectUser: (projectId, projectUser, send_invite) => ApiService.post(`/projects/${projectId}/project_users`, createProjectUserParams(projectUser, send_invite), { formData: true, includeUploadProgress: true })
        .pipe(map((response) => {
        return Object.assign(Object.assign({}, response), { response: response.response ? { project_user: plainToInstance(ProjectUser, response.response.project_user), is_invited: response.response.is_invited } : null });
    })),
    createProjectUserAdmin: (projectId, projectUser, send_invite) => ApiService.post(`/projects/${projectId}/admin/project_users`, createProjectUserParams(projectUser, send_invite), { formData: true, includeUploadProgress: true })
        .pipe(map((response) => {
        return Object.assign(Object.assign({}, response), { response: response.response ? { project_user: plainToInstance(ProjectUser, response.response.project_user), is_invited: response.response.is_invited } : null });
    })),
    updateProjectUser: (projectId, userId, projectUser) => ApiService.put(`/projects/${projectId}/project_users/${userId}`, createProjectUserParams(projectUser), { formData: true, includeUploadProgress: true })
        .pipe(map((response) => {
        return Object.assign(Object.assign({}, response), { response: response.response ? plainToInstance(ProjectUser, response.response) : null });
    })),
    updateProjectUserAdmin: (projectId, userId, projectUser) => ApiService.put(`/projects/${projectId}/admin/project_users/${userId}`, createProjectUserParams(projectUser), { formData: true, includeUploadProgress: true })
        .pipe(map((response) => {
        return Object.assign(Object.assign({}, response), { response: response.response ? plainToInstance(ProjectUser, response.response) : null });
    })),
    deleteProfilePicture: (projectId, userId) => ApiService.delete(`/projects/${projectId}/project_users/${userId}/profile_picture`)
};

import { Container } from "react-bootstrap";
import { useProject } from "../../global/layouts/project/ProjectLayout";

const ProjectPage = () => {
  const { project } = useProject();
  return (
    <Container>
    <p>{project?.name}</p>
    <p>{project?.address1}</p>
    </Container>
  )
};
export default ProjectPage;
import { CompanyDailyReport } from '../../models/CompanyDailyReport.js';
import { ActivityFull, ActivityLogResponse, ActivitiesGroupedByCompany } from './../../models/Activity.js';
import { plainToInstance } from 'class-transformer';
import { map } from 'rxjs';
import { ActivityLite } from '../../models/Activity.js';
import { ApiService } from './api-base.js';
import { dateUtils } from '../../models/DateUtils.js';
const activityParams = (activity) => {
    var _a;
    return ({
        name: activity.name,
        start_time_minutes: activity.start_time_minutes,
        end_time_minutes: activity.end_time_minutes,
        planned_start_date: activity.planned_start_date,
        planned_end_date: activity.planned_end_date || activity.planned_start_date,
        is_critical: activity.is_critical,
        location_group_id: activity.location_group_id,
        is_all_day: activity.is_all_day,
        working_calendar_id: activity.working_calendar_id !== undefined ? activity.working_calendar_id : (_a = activity.working_calendar) === null || _a === void 0 ? void 0 : _a.id,
        activity_type: activity.activity_type
    });
};
const linkParams = (links) => {
    return links.map(link => {
        var _a, _b;
        return ({
            predecessor_activity_id: (_a = link.predecessor_activity) === null || _a === void 0 ? void 0 : _a.id,
            successor_activity_id: (_b = link.successor_activity) === null || _b === void 0 ? void 0 : _b.id,
            link_relationship_type: link.link_relationship_type, lag_days: link.lag_days
        });
    });
};
const assignmentParams = (assignments) => {
    return assignments.map(a => ({
        company_id: a.company.id,
        project_user_ids: a.project_user_ids
    }));
};
const createActivityParams = (activity, assignments, links) => ({
    activity: activityParams(activity),
    assignments: assignmentParams(assignments),
    links: linkParams(links)
});
const updateActivityParams = (activity, assignment, links) => ({
    activity: activityParams(activity),
    assignment: {
        company_id: assignment.company.id,
        project_user_ids: assignment.project_user_ids
    },
    links: linkParams(links)
});
export const ActivityService = {
    getPlannedStartDateFromLinks: (projectId, links, working_calendar_id, company_id) => ApiService.put(`/projects/${projectId}/planned_start_date_from_links`, { links: linkParams(links), activity: { working_calendar_id, company_id } })
        .pipe(map((response) => {
        if (response.planned_start_date) {
            return dateUtils.deserializeDate(response.planned_start_date);
        }
        return undefined;
    })),
    getMyActivities: (projectId, filters) => ApiService.getWithQuery(ApiService.appendQueryParams(`/projects/${projectId}/my-activities`, filters))
        .pipe(map((response) => {
        const res = {
            today: plainToInstance(ActivitiesGroupedByCompany, response.today_activities),
            delay_events: plainToInstance(ActivitiesGroupedByCompany, response.delay_events),
            upcoming: plainToInstance(ActivitiesGroupedByCompany, response.upcoming_activities),
            recently_closed: plainToInstance(ActivitiesGroupedByCompany, response.recently_closed_activities),
            today_rain_delay: response.today_rain_delay,
            today_holiday: response.today_holiday,
            today_company_report_map: new Map(),
        };
        const today_reports = plainToInstance(CompanyDailyReport, response.today_company_reports);
        // map company daily reports
        today_reports.forEach((r) => res.today_company_report_map.set(r.company_id, r));
        return res;
    })),
    getActivities: (projectId, scheduleId, filters) => {
        return ApiService.get(ApiService.appendQueryParams(`/projects/${projectId}/schedules/${scheduleId}/activities`, filters))
            .pipe(map((response) => plainToInstance(ActivityLite, response)));
    },
    getAssignedActivities: (projectId, filters) => ApiService.get(ApiService.appendQueryParams(`/projects/${projectId}/assigned-activities`, filters))
        .pipe(map((response) => plainToInstance(ActivityFull, response))),
    getPublishedActivities: (projectId, filters) => {
        return ApiService.get(ApiService.appendQueryParams(`/projects/${projectId}/public-activities`, filters))
            .pipe(map((response) => plainToInstance(ActivityLite, response)));
    },
    getPublishedActivitiesAdmin: (projectId, filters) => {
        return ApiService.get(ApiService.appendQueryParams(`/projects/${projectId}/admin/public-activities`, filters))
            .pipe(map((response) => plainToInstance(ActivityLite, response)));
    },
    getPrivateActivities: (projectId, schedule_ids, filters) => {
        return ApiService.get(ApiService.appendQueryParams(`/projects/${projectId}/private-activities`, Object.assign(Object.assign({}, filters), { schedule_ids })))
            .pipe(map((response) => plainToInstance(ActivityLite, response)));
    },
    getActivity: (projectId, activityId) => ApiService.get(`/projects/${projectId}/activities/${activityId}`)
        .pipe(map((response) => plainToInstance(ActivityFull, response))),
    getActivityAdmin: (projectId, activityId) => ApiService.get(`/projects/${projectId}/admin/activities/${activityId}`)
        .pipe(map((response) => plainToInstance(ActivityFull, response))),
    getActivityWithYesterdayState: (projectId, activityId) => ApiService.get(`/projects/${projectId}/activities/${activityId}/yesterday_state`)
        .pipe(map((response) => plainToInstance(ActivityFull, response))),
    getActivityLogs: (projectId, activityId) => ApiService.get(`/projects/${projectId}/activities/${activityId}/show_logs`)
        .pipe(map((response) => plainToInstance(ActivityLogResponse, response)), map((response) => {
        const arr = [...(response.metas), ...(response.state_logs)];
        return arr.sort((a, b) => a.created_at - b.created_at);
    })),
    sendReminderNotification: (projectId, activityId) => ApiService.put(`/projects/${projectId}/activities/${activityId}/reminder_notification`, {}),
    createPublishedActivity: (projectId, activity, assignments, links) => ApiService.post(`/projects/${projectId}/public-activities`, createActivityParams(activity, assignments, links)),
    createPublishedActivityAdmin: (projectId, activity, assignments, links) => ApiService.post(`/projects/${projectId}/admin/public-activities`, createActivityParams(activity, assignments, links)),
    createActivity: (projectId, scheduleId, activity, assignments, links) => ApiService.post(`/projects/${projectId}/schedules/${scheduleId}/activities`, createActivityParams(activity, assignments, links)),
    updateActivity: (projectId, activityId, activity, assignment, links) => ApiService.put(`/projects/${projectId}/activities/${activityId}`, updateActivityParams(activity, assignment, links))
        .pipe(map((response) => plainToInstance(ActivityFull, response))),
    updateActivityAdmin: (projectId, activityId, activity, assignment, links) => ApiService.put(`/projects/${projectId}/admin/activities/${activityId}`, updateActivityParams(activity, assignment, links))
        .pipe(map((response) => plainToInstance(ActivityFull, response))),
    updateActivityState: (projectId, activityId, state, state_date, start_date_override, end_date_override) => ApiService.put(`/projects/${projectId}/activities/${activityId}/state`, { activity: { state, start_date_override, end_date_override }, state_date })
        .pipe(map((response) => ({ activity: plainToInstance(ActivityFull, response.activity), related_activities: plainToInstance(ActivityLite, response.related_activities) }))),
    deleteActivity: (projectId, activityId) => ApiService.delete(`/projects/${projectId}/activities/${activityId}`),
    createActivityComment: (projectId, activityId, body, image) => ApiService.post(`/projects/${projectId}/activities/${activityId}/comment`, { comment: { body, image } }, { formData: true, includeUploadProgress: true })
};

import { plainToInstance } from 'class-transformer';
import { map } from 'rxjs';
import { ProjectLite } from '../../models/Project.js';
import { ApiService } from './api-base.js';
const createProjectParams = (project) => {
    const body = {
        project: {
            name: project.name,
            address1: project.address1,
            address2: project.address2,
            city: project.city,
            state: project.state,
            zipcode: project.zipcode,
            time_zone: project.time_zone,
            push_notifications_enabled: project.push_notifications_enabled,
            profile_picture: project.profile_picture_upload_file,
            daily_reports_enabled: project.daily_reports_enabled,
            project_daily_report_emails: project.project_daily_report_emails
        }
    };
    // this is returning 'undefined'? TODO fix this
    if (!body.project.profile_picture) {
        delete body.project.profile_picture;
    }
    return body;
};
export const ProjectService = {
    getProjects: (query) => {
        return ApiService.getWithQuery(`/projects`, query)
            .pipe(map((response) => plainToInstance(ProjectLite, response)));
    },
    getAllProjects: (query) => ApiService.getWithQuery(`/projects/all`, query)
        .pipe(map((response) => plainToInstance(ProjectLite, response))),
    getProject: (projectId) => ApiService.get(`/projects/${projectId}`)
        .pipe(map((response) => plainToInstance(ProjectLite, response))),
    createProject: (project) => ApiService.post(`/projects`, createProjectParams(project), { formData: true, includeUploadProgress: true })
        .pipe(map((response) => {
        return Object.assign(Object.assign({}, response), { response: response.response ? plainToInstance(ProjectLite, response.response) : null });
    })),
    updateProject: (projectId, project) => ApiService.put(`/projects/${projectId}`, createProjectParams(project), { formData: true, includeUploadProgress: true })
        .pipe(map((response) => {
        return Object.assign(Object.assign({}, response), { response: response.response ? plainToInstance(ProjectLite, response.response) : null });
    })),
};

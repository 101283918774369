import { LocationGroup, LocationGroupService } from "@lookahead/core";
import { useEffect, useState, useCallback } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { useNavigate } from "react-router-dom";
import { useProject } from "../../global/layouts/project/ProjectLayout";
import _ from 'lodash';

const MAX_DEPTH = 7;
const LocationGroupsPage = () => {
  const [locationGroups, setLocationGroups] = useState<LocationGroup[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [activeEditId, setActiveEditId] = useState<number | null>(null);
  const [editText, setEditText] = useState<string>('');
  const [createText, setCreateText] = useState<string>('');
  const { project } = useProject();
  const [selectedLocations, setSelectedLocations] = useState<LocationGroup[]>([]);

  const onActiveEdit = (location: LocationGroup) => {
    setEditText(location.name);
    setActiveEditId(location.id);
  }

  const onFocusCreateInput = () => {
    setActiveEditId(null);
    setEditText('');
  };

  const onSubmitEdit = useCallback(() => {
    const index = locationGroups.findIndex(l => l.id === activeEditId);
    const copy = _.cloneDeep(locationGroups[index]);
    copy.name = editText;
    LocationGroupService.updateLocationGroup(project.id, activeEditId, copy)
      .subscribe({
        next: (response) => {
          setLocationGroups(l => {
            l[index] = response;
            return l;
          });
          setActiveEditId(null);
          setEditText('');
        },
        error: err => console.log(err)
      });
  }, [project, locationGroups, editText, activeEditId]);

  const onSubmitCreate = useCallback(() => {
    const l = new LocationGroup();
    l.name = createText;
    if (selectedLocations.length > 0) {
      l.parent_id = selectedLocations[selectedLocations.length - 1]?.id;
    }
    LocationGroupService.createLocationGroup(project.id, l)
      .subscribe({
        next: (response) => {
          setLocationGroups(l => {
            return [...l, response];
          });
          setCreateText('');
        },
        error: err => console.log(err)
      });
  }, [project, createText, selectedLocations]);

  const onDeleteLocation = (location: LocationGroup) => {
    LocationGroupService.getLocationGroupActivitiesCount(project.id, location.id).subscribe({
      next: count => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Are you sure you want to delete ${location.name}?\n${count} activities will be reassigned.`)) {
          LocationGroupService.deleteLocationGroupAdmin(project.id, location.id).subscribe({
            next: () => {
              setLocationGroups(l => {
                return l.filter((lo) => lo.id !== location.id);
              })
            },
            error: err => console.log(err)
          });
        }
      },
      error: err => console.log(err)
    })
  };

  const getDescendentLocations = (location?: LocationGroup) => {
    LocationGroupService.getLocationGroups(project.id, location?.id ).subscribe({
      next: locations => {
        setLocationGroups(locations || []);
      },
      error: _ => {}
    });
  };

  const previousTier = () => {
    if (currentIndex === 0) {
      return;
    }
    const lastParent: LocationGroup | undefined = selectedLocations[currentIndex - 2];
    setSelectedLocations(l => {
      const c = _.cloneDeep(l);
      c.splice(l.length - 1, 1);
      return c;
    });
    setCurrentIndex(() => currentIndex - 1);
    getDescendentLocations(lastParent);
  };

  const nextTier = (location: LocationGroup) => {
    if (currentIndex === MAX_DEPTH) {
      return;
    }
    const nextIndex = currentIndex + 1;
    setCurrentIndex(() => nextIndex);
    setSelectedLocations(l => [...l, location])
    getDescendentLocations(location);
  };

  useEffect(() => {
    LocationGroupService.getLocationGroups(project.id).subscribe({
      next: response => {
        setLocationGroups(response);
      },
      error: err => {
        console.log(err);
      }
    })
  }, [project]);
  return (
    <Container>
      <Row style={{marginTop: 24, justifyContent: 'space-between', alignItems: 'flex-end'}}>
        <Col>
        <h2 style={{marginBottom: 0}}>Location Groups</h2>
        <h4 style={{marginTop: 6, cursor: 'pointer'}} onClick={() => previousTier()}>
          {currentIndex === 0 ? '' : '< '}Tier {currentIndex + 1}
        </h4>
        <h5>
          {selectedLocations.map((location, i) =>
            location.name + (i === selectedLocations.length - 1 ? '' : ' > '))}
        </h5>
        </Col>
      </Row>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {locationGroups.map((location, i) =>
          <tr key={i}>
            <td>{location.id}</td>
            <td>
              {activeEditId !== location.id &&
              <div onClick={() => onActiveEdit(location)}>{location.name}</div>
              }
              {activeEditId === location.id &&
              <input autoFocus
                onBlur={() => setActiveEditId(null)}
                value={editText}
                onChange={(e) => setEditText(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' ? onSubmitEdit() : undefined}/>
              }
            </td>
            <td>
              {currentIndex !== MAX_DEPTH &&
              <Button variant="outline-primary" size="sm" onClick={() => {nextTier(location)}} style={{marginRight: 6}}>Next</Button>
              }
              <Button variant="outline-danger" size="sm" onClick={() => onDeleteLocation(location)}>Delete</Button>
            </td>
          </tr>
          )}
          <tr>
            <td>New Location Group</td>
            <td><input onFocus={onFocusCreateInput}
                value={createText}
                onChange={(e) => setCreateText(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' ? onSubmitCreate() : undefined}/></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
};

export default LocationGroupsPage;
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from "react";
import AppFormControl from '../../global/form-control/AppFormControl';
import AppErrorMessage from '../../global/error-message/AppErrorMessage';
import { ProjectLite, ProjectService, TimeZoneLabel, USATimeZones, UploadProgress } from '@lookahead/core';
import Alert from 'react-bootstrap/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import { Observable } from 'rxjs';
import { FileUploader } from "react-drag-drop-files";
import fileUtils from '../../../services/fileUtils';


const CreateEditProjectPage = () => {
  const { projectId } = useParams();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
                  .required('Name is required'),
    address1: Yup.string().optional().nullable(),
    address2: Yup.string().optional().nullable(),
    city: Yup.string().optional().nullable(),
    state: Yup.string().optional().nullable(),
    zipcode: Yup.string().optional().nullable(),
    time_zone: Yup.string().required('Time Zone is required'),
    push_notifications_enabled: Yup.boolean().optional().nullable(),
    daily_reports_enabled: Yup.boolean().optional().nullable(),
    profile_picture: Yup.object().optional().nullable(),
    project_daily_report_emails: Yup.string().optional().nullable()
  });
  const formOptions = { resolver: yupResolver(validationSchema), defaultValues: {
    time_zone: 'America/Los_Angeles', push_notifications_enabled: true,  daily_reports_enabled: false
  }};
  const { register, handleSubmit, formState, reset, getValues } = useForm(formOptions);
  const { errors } = formState;
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const isEdit = () => projectId !== undefined;
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  const handleChangeFile = (file: File) => {
    fileUtils.resizeImage(file, 0.9)
      .then(resizedImg => setFile(resizedImg as File));
  };

  const onSubmit = useCallback((data: any) => {
    if (isSubmitting) {
      return;
    }
    setErrorMessage(undefined);
    setIsSubmitting(true);
    const newProject = new ProjectLite();
    newProject.name = data.name;
    newProject.address1 = data.address1;
    newProject.address2 = data.address2;
    newProject.city = data.city;
    newProject.state = data.state;
    newProject.zipcode = data.zipcode;
    newProject.time_zone = data.time_zone;
    newProject.project_daily_report_emails = data.project_daily_report_emails ? data.project_daily_report_emails.split(',').map(e => ({email: e.trim()})) : [];
    newProject.daily_reports_enabled = data.daily_reports_enabled === null ?
                                        false :
                                        data.daily_reports_enabled;
    newProject.push_notifications_enabled = data.push_notifications_enabled === null ?
                                              false :
                                              data.push_notifications_enabled;

    if (file) {
      newProject.profile_picture_upload_file = file;
    }
    let request: Observable<UploadProgress<any>> = ProjectService.createProject(newProject);
    if (isEdit()) {
      request = ProjectService.updateProject(projectId as any, newProject);
    }
    request.subscribe({
      next: (progress) => {
        if (!progress.response) {
          return;
        }
        setIsSubmitting(false);
        navigate('/', {replace: true});
      },
      error: e => {
        setErrorMessage('Save Failed: Unable to save project changes.');
        setIsSubmitting(false);
      }
    });
  }, [file]);

  useEffect(() => {
    if (!file) {
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  useEffect(() => {
    if (!projectId) {
      return;
    }
    setErrorMessage(undefined);
    ProjectService.getProject(Number.parseInt(projectId)).subscribe({
      next: project => {
        reset({
          name: project.name,
          address1: project.address1,
          address2: project.address2,
          city: project.city,
          state: project.state,
          zipcode: project.zipcode,
          time_zone: project.time_zone,
          daily_reports_enabled: project.daily_reports_enabled,
          push_notifications_enabled: project.push_notifications_enabled,
          profile_picture: project.profile_picture,
          project_daily_report_emails: project.project_daily_report_emails?.length > 0 ? project.project_daily_report_emails.map(e => e.email).join(', ') : null
        });
      }
    });
  }, [projectId, reset]);

  return (
    <Container style={{marginTop: 16}}>
      <h2>{isEdit() ? 'Edit' : 'New'} Project</h2>
      {errorMessage !== undefined && <Alert variant='danger'>{errorMessage}</Alert>}
      {!errorMessage?.includes('404') &&
      <Form onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))}>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <AppFormControl {...register('name')} placeholder="" error={errors.name || undefined} />
          <AppErrorMessage name='name' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="address1">
          <Form.Label>Address Line 1</Form.Label>
          <AppFormControl {...register('address1')}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="address2">
          <Form.Label>Address Line 2</Form.Label>
          <AppFormControl {...register('address2')}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="city">
          <Form.Label>City</Form.Label>
          <AppFormControl {...register('city')}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="state">
          <Form.Label>State</Form.Label>
          <AppFormControl {...register('state')}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="zipcode">
          <Form.Label>Zipcode</Form.Label>
          <AppFormControl {...register('zipcode')}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="time_zone">
          <Form.Label>Time Zone</Form.Label>
          <Form.Select {...register('time_zone')}>
            {USATimeZones.map((t, i) => <option value={t} key={i}>{TimeZoneLabel[t]}</option>)}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="report_settings">
          <Form.Label>Report Settings</Form.Label>
          <Form.Check {...register('daily_reports_enabled')} id="daily_reports_enabled" label='Daily Reports Enabled' />
          <Form.Label>Daily Report Recipient Emails (<i>comma separated list</i>)</Form.Label>
          <AppFormControl {...register('project_daily_report_emails')} placeholder='email1, email2, email3'/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="push_notifications_enabled">
          <Form.Label>Project Settings</Form.Label>
          <Form.Check {...register('push_notifications_enabled')} id="push_notifications_enabled" label='Push Notifications Enabled' />
        </Form.Group>
        <Form.Group className="mb-3" controlId="zipcode">
          <Form.Label>Project Picture</Form.Label>
          <div>
            {!preview && getValues('profile_picture') && <img src={getValues('profile_picture')} alt="Project" height="120" width="auto" />}
            {preview && <img src={preview} alt="Project" height="120" width="auto" />}
            <FileUploader handleChange={handleChangeFile} name="file" types={['JPG', 'PNG', 'GIF', 'JPEG']} />
          </div>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      }
    </Container>
  );
};
export default CreateEditProjectPage;
import { CompanyLite, CompanyService } from "@lookahead/core";
import { useEffect, useState, useCallback } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { debounce } from 'lodash';
import { useNavigate } from "react-router-dom";
import { useProject } from "../../global/layouts/project/ProjectLayout";

const CompaniesPage = () => {
  const [companies, setCompanies] = useState<CompanyLite[]>([]);
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();
  const { project } = useProject();

  const handleDebounce = (value: string) => {
    CompanyService.getCompaniesAdmin(project.id, value).subscribe({
      next: response => {
        setCompanies(response);
      },
      error: err => {
        console.log(err);
      }
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceQuery = useCallback(debounce(handleDebounce, 500), []);
  const handleQueryChange = (event: any) => {
      setQuery(event.target.value);
      debounceQuery(event.target.value);
  };

  useEffect(() => {
    CompanyService.getCompaniesAdmin(project.id).subscribe({
      next: response => {
        setCompanies(response);
      },
      error: err => {
        console.log(err);
      }
    })
  }, [project]);
  return (
    <Container>
      <Row style={{marginTop: 24, marginBottom: 12, justifyContent: 'space-between', alignItems: 'flex-end'}}>
        <Col><h2 style={{marginBottom: 0}}>Companies</h2></Col>
        <Col style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        <Button size='sm' style={{marginRight: 8}} onClick={() => navigate(`/projects/${project.id}/companies/new`)}>New Company</Button>
        <input placeholder="Search" value={query} onChange={handleQueryChange}/>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Trade</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company, i) =>
          <tr key={i}>
            <td>{company.id}</td>
            <td>{company.name}</td>
            <td>{company.trade}</td>
            <td>
              <Button variant="outline-secondary" size="sm" onClick={() => navigate(`${company.id}/edit`)}>Edit</Button>
            </td>
          </tr>
          )}
        </tbody>
      </Table>
    </Container>
  )
};

export default CompaniesPage;
import { ActivityLite, ActivityService, activityUtils, dateUtils } from "@lookahead/core";
import { useEffect, useState, useCallback } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { debounce } from 'lodash';
import { useNavigate } from "react-router-dom";
import { useProject } from "../../global/layouts/project/ProjectLayout";

const ActivitiesPage = () => {
  const [activities, setActivities] = useState<ActivityLite[]>([]);
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();
  const { project } = useProject();

  const handleDebounce = (value: string) => {
    ActivityService.getPublishedActivitiesAdmin(project.id, {query: value}).subscribe({
      next: response => {
        setActivities(response);
      },
      error: err => {
        console.log(err);
      }
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceQuery = useCallback(debounce(handleDebounce, 500), []);
  const handleQueryChange = (event: any) => {
      setQuery(event.target.value);
      debounceQuery(event.target.value);
  };

  useEffect(() => {
    ActivityService.getPublishedActivitiesAdmin(project.id).subscribe({
      next: response => {
        setActivities(response);
      },
      error: err => {
        console.log(err);
      }
    })
  }, [project]);
  return (
    <Container>
      <Row style={{marginTop: 24, marginBottom: 12, justifyContent: 'space-between', alignItems: 'flex-end'}}>
        <Col><h2 style={{marginBottom: 0}}>Published Activities</h2></Col>
        <Col style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        <Button size='sm' style={{marginRight: 8}} onClick={() => navigate(`/projects/${project.id}/activities/new`)}>New Activity</Button>
        <input placeholder="Search" value={query} onChange={handleQueryChange}/>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Activity</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {activities.map((activity, i) =>
          <tr key={i}>
            <td>{activity.id}</td>
            <td>
              <div>{activity.company?.name} - {activity.name}</div>
              {activity.location_group_id && <div>{activityUtils.locationStr(activity)}</div>}
              <div>{dateUtils.format(activity.planned_start_date, 'M/D/YYYY')} - {dateUtils.format(activity.planned_end_date, 'M/D/YYYY')}</div>
              <div>{activity.is_critical ? 'Critical Path' : 'Non-Critical Path'}</div>
            </td>
            <td>
              <Button variant="outline-secondary" size="sm" onClick={() => navigate(`${activity.id}/edit`)}>Edit</Button>
            </td>
          </tr>
          )}
        </tbody>
      </Table>
    </Container>
  )
};

export default ActivitiesPage;
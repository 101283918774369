import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from "react";
import AppFormControl from '../../global/form-control/AppFormControl';
import AppErrorMessage from '../../global/error-message/AppErrorMessage';
import { Company, CompanyService } from '@lookahead/core';
import Alert from 'react-bootstrap/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import { useProject } from '../../global/layouts/project/ProjectLayout';

const CreateEditCompanyPage = () => {
  const { companyId } = useParams();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
                  .required('Name is required'),
    trade: Yup.string()
                  .required('Trade is required')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const isEdit = () => companyId !== undefined;
  const { project } = useProject();

  const onSubmit = (data: any) => {
    if (isSubmitting) {
      return;
    }
    setErrorMessage(undefined);
    setIsSubmitting(true);
    const newCompany = new Company();
    newCompany.name = data.name;
    newCompany.trade = data.trade;

    let request = CompanyService.createCompanyAdmin(project.id, newCompany);
    if (isEdit()) {
      request = CompanyService.updateCompanyAdmin(project.id, Number.parseInt(companyId), newCompany)
    }
    request.subscribe({
      next: () => {
        setIsSubmitting(false);
        navigate(`/projects/${project.id}/companies`);
      },
      error: () => {
        setErrorMessage('Save Failed: Unable to save company changes.');
        setIsSubmitting(false);
      }
    });
  };

  useEffect(() => {
    if (!companyId) {
      return;
    }
    setErrorMessage(undefined);
    CompanyService.getCompanyAdmin(project.id, Number.parseInt(companyId)).subscribe({
      next: company => {
        reset({
          name: company.name,
          trade: company.trade
        });
      }
    });
  }, [project, companyId, reset]);

  return (
    <Container style={{marginTop: 16}}>
      <h2>{isEdit() ? 'Edit' : 'New'} Company</h2>
      {errorMessage !== undefined && <Alert variant='danger'>{errorMessage}</Alert>}
      {!errorMessage?.includes('404') &&
      <Form onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))}>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <AppFormControl {...register('name')} placeholder="" error={errors.name || undefined} />
          <AppErrorMessage name='name' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="trade">
          <Form.Label>Trade</Form.Label>
          <AppFormControl {...register('trade')} placeholder="" error={errors.trade || undefined} />
          <AppErrorMessage name='trade' errors={errors}/>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      }
    </Container>
  );
};
export default CreateEditCompanyPage;
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from "react";
import AppFormControl from '../../global/form-control/AppFormControl';
import AppErrorMessage from '../../global/error-message/AppErrorMessage';
import { AuthService, stringUtils } from '@lookahead/core';
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../services/contexts/auth-context';

const LoginPage = () => {
  const validationSchema = Yup.object().shape({
    username: Yup.string()
                  .required('Username is required'),
    password: Yup.string()
                  .required('Password is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const navigate = useNavigate();
  const { logout, setAuthToken } = useAuth();

  const onSubmit = ({username, password}: {username: string, password: string}) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setIsInvalid(false);
    const email = stringUtils.isEmail(username) ? username : undefined;
    const phone = email === undefined ? username : undefined;
    AuthService.login(password, null, email, phone).subscribe({
      next: response => {
        setAuthToken(response.auth_token);
        setIsSubmitting(false);
        navigate('/', {replace: true});
      },
      error: () => {
        setIsInvalid(true);
        setIsSubmitting(false);
      }
    });
  };

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <Container>
      <h2 style={{marginTop: 24}}>Admin Login</h2>
      {isInvalid && <Alert variant='danger'>Login Failed: Your username or password is incorrect.</Alert>}
      <Form onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Username</Form.Label>
          <AppFormControl {...register('username')} placeholder="Phone number or email" error={errors.username || undefined} />
          <AppErrorMessage name='username' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <AppFormControl {...register('password')}
            type="password"
            placeholder="Password"
            error={errors.password || undefined} />
          <AppErrorMessage name='password' errors={errors}/>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};
export default LoginPage;
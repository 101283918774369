import { plainToInstance } from 'class-transformer';
import { map } from 'rxjs';
import { User } from '../../models/User.js';
import { ApiService } from './api-base.js';
const createUserParams = (user, send_invite) => {
    return {
        user: {
            first_name: user.first_name,
            last_name: user.last_name,
            position: user.position,
            email: user.email,
            phone: user.phone,
            app_role: user.app_role
        },
        send_invite
    };
};
export const UserService = {
    getUsers: (query) => {
        return ApiService.getWithQuery(`/users`, query)
            .pipe(map((response) => plainToInstance(User, response)));
    },
    getUser: (userId) => ApiService.get(`/users/${userId}`)
        .pipe(map((response) => plainToInstance(User, response))),
    createUser: (user, send_invite) => ApiService.post(`/users/`, createUserParams(user, send_invite))
        .pipe(map((response) => plainToInstance(User, response))),
    updateUser: (userId, user) => ApiService.put(`/users/${userId}`, createUserParams(user))
        .pipe(map((response) => plainToInstance(User, response))),
    resetPassword: (password) => ApiService.put(`/users/reset_password`, { password }),
    deleteProfilePicture: (userId) => ApiService.delete(`/users/${userId}/profile_picture`),
    sendInvite: (userId) => ApiService.put(`/users/${userId}/invite`, {})
};

import { createContext, useContext } from "react";
import { AuthService, AuthToken } from "@lookahead/core";

type AuthContextType = {
  authToken: (AuthToken | undefined),
  setAuthToken: any
};

export const AuthContext = createContext<AuthContextType>(
  {
    authToken: undefined,
    setAuthToken: undefined
  }
);

export const useAuth = () => {
  const { authToken, setAuthToken } = useContext(AuthContext);
  return {
    isAuthenticated: authToken !== undefined,
    logout: () => {
      AuthService.logout().subscribe();
      setAuthToken(undefined);
    },
    setAuthToken
  }
};
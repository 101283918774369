import Modal from 'react-bootstrap/Modal';
import { Button, Col, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { ModalProps } from '../ModalProps';
import { LocationGroup, LocationGroupService, ProjectLite } from '@lookahead/core';
import { useEffect, useState } from 'react';
import _ from 'lodash';

type SelectLocationGroupModalProps = {
  selectedLocations?: LocationGroup[];
  project: ProjectLite;
  onSave: (locations?: LocationGroup[]) => void;
} & ModalProps;

const MAX_DEPTH = 7;
const SelectLocationGroupModal = (props: SelectLocationGroupModalProps) => {
  const [selectedLocations, setSelectedLocations] = useState<LocationGroup[]>([]);
  const [locationGroups, setLocationGroups] = useState<LocationGroup[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const getDescendentLocations = (location?: LocationGroup) => {
    LocationGroupService.getLocationGroups(props.project.id, location?.id ).subscribe({
      next: locations => {
        setLocationGroups(locations || []);
      },
      error: _ => {}
    });
  };

  const previousTier = () => {
    if (currentIndex === 0) {
      return;
    }
    const lastParent: LocationGroup | undefined = selectedLocations[currentIndex - 2];
    setSelectedLocations(l => {
      const c = _.cloneDeep(l);
      c.splice(l.length - 1, 1);
      return c;
    });
    setCurrentIndex(() => currentIndex - 1);
    getDescendentLocations(lastParent);
  };

  const nextTier = (location: LocationGroup) => {
    if (currentIndex === MAX_DEPTH) {
      return;
    }
    const nextIndex = currentIndex + 1;
    setCurrentIndex(() => nextIndex);
    setSelectedLocations(l => [...l, location])
    getDescendentLocations(location);
  };

  useEffect(() => {
    let locationId: number;
    if (props.selectedLocations?.length) {
      if (props.selectedLocations.length > 0) {
        locationId = props.selectedLocations[props.selectedLocations.length - 1]?.id;
      }
      setCurrentIndex(props.selectedLocations.length - 1);
      setSelectedLocations(props.selectedLocations);
    }
    LocationGroupService.getLocationGroups(props.project.id, locationId).subscribe({
      next: groups => setLocationGroups(groups),
      error: err => console.log(err)
    });
  }, [props.show, props.selectedLocations, props.project]);

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Location Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Row style={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
        <Col>
        {selectedLocations.length > 0 &&
        <>
        <h5>Selected Location Group:</h5>
        <h6>
          {selectedLocations.map((location, i) =>
            location.name + (i === selectedLocations.length - 1 ? '' : ' > '))}
        </h6>
        <hr />
        </>
        }
        </Col>
      </Row>
      <h5 style={{cursor: 'pointer'}} onClick={() => previousTier()}>
          {currentIndex === 0 ? '' : '< '}Tier {currentIndex + 1}
        </h5>
      {locationGroups.length === 0 ?
      <div>No Location Groups found in "{selectedLocations.length > 0 ? selectedLocations[selectedLocations.length -1].name : props.project.name}"</div> :
      <Table striped bordered>
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {locationGroups.map((location, i) =>
          <tr key={i}>
            <td>
              <div>{location.name}</div>
            </td>
            <td>
              {currentIndex !== MAX_DEPTH &&
              <Button variant="outline-primary" size="sm" onClick={() => {nextTier(location)}} style={{marginRight: 6}}>Select</Button>
              }
            </td>
          </tr>
          )}
        </tbody>
      </Table>
      }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => {props.onSave(selectedLocations); props.onClose();}}>Save</Button>
      </Modal.Footer>
    </Modal>

  );
};
export default SelectLocationGroupModal;

export enum SIZE {
  XXL= 'xxl',
  XL = 'xl',
  LG = 'lg',
  MED = 'med',
  SM = 'sm',
  XS = 'xs'
}

const colors = {
  blue100: '#30A9FB',
  blue150: '#0095FF',
  blue200: '#0195F9',
  blue250: '#007AFF',
  orange: '#F6AC3D',
  red50: '#FBECF2',
  red100: '#EB4D3D',
  red150: '#FB3C2F',
  green100: '#34C759',
  green150: '#4FBF67',
  gray50: '#F2F3F5',
  gray100: '#C5C5C5',
  gray150: '#EEEEF0',
  gray200: '#85858A',
  gray250: '#8E8E8E',
  gray300: '#8B8B8B',
  black: '#000000',
  white: '#ffffff'
};

const fontSize = {
  xxl: '41px',
  xl: '24px',
  lg: '22px',
  med: '16px',
  sm: '14px',
  tiny: '12px'
};

const padding = {
  cardPadding: 12
}

export const Theme = {
  colors,
  fontSize,
  padding
};
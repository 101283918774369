import { ProjectUserLite, ProjectUserService, UserService, User } from "@lookahead/core";
import { useEffect, useState, useCallback } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { debounce } from 'lodash';
import { useNavigate } from "react-router-dom";
import { useProject } from "../../global/layouts/project/ProjectLayout";
import { switchMap } from "rxjs";

const ProjectUsersPage = () => {
  const [projectUsers, setProjectUsers] = useState<ProjectUserLite[]>([]);
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();
  const { project } = useProject();

  const handleDebounce = (value: string) => {
    ProjectUserService.getProjectUsersAdmin(project.id, undefined, value).subscribe({
      next: response => {
        setProjectUsers(response);
      },
      error: err => {
        console.log(err);
      }
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceQuery = useCallback(debounce(handleDebounce, 500), []);
  const handleQueryChange = (event: any) => {
      setQuery(event.target.value);
      debounceQuery(event.target.value);
  };

  const onSendInvite = (userId: number) => {
    UserService.sendInvite(userId).pipe(switchMap(() => ProjectUserService.getProjectUsersAdmin(project.id, undefined, query))).subscribe({
      next: (response) => {
        setProjectUsers(response);
        alert('Invite Sent!')
      },
      error: () => alert('Unable to send invitation. Check phone number and try again.')
    });
  }

  useEffect(() => {
    ProjectUserService.getProjectUsersAdmin(project.id).subscribe({
      next: response => {
        setProjectUsers(response);
      },
      error: err => {
        console.log(err);
      }
    })
  }, [project]);
  return (
    <Container>
      <Row style={{marginTop: 24, marginBottom: 12, justifyContent: 'space-between', alignItems: 'flex-end'}}>
        <Col><h2 style={{marginBottom: 0}}>Project Users</h2></Col>
        <Col style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        <Button size='sm' style={{marginRight: 8}} onClick={() => navigate(`/projects/${project.id}/project-users/new`)}>New Project User</Button>
        <input placeholder="Search" value={query} onChange={handleQueryChange}/>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Company</th>
            <th>Role</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {projectUsers.map((projectUser, i) =>
          <tr key={i}>
            <td>{projectUser.id}</td>
            <td>{projectUser.user.fullName}</td>
            <td>{projectUser.company.name}</td>
            <td>{projectUser.project_role}</td>
            <td>
              <Button variant="outline-secondary" size="sm" onClick={() => navigate(`${projectUser.id}/edit`)}>Edit</Button>
              {!(projectUser.user as User).accepted_invite && (projectUser.user as User).invited &&
              <Button variant="outline-success" size="sm" onClick={() => onSendInvite(projectUser.user.id)} style={{marginLeft: 4}}>Resend Invite</Button>
              }
              {!(projectUser.user as User).accepted_invite && !(projectUser.user as User).invited &&
              <Button variant="success" size="sm" onClick={() => onSendInvite(projectUser.user.id)} style={{marginLeft: 4}}>Send Invite</Button>
              }
            </td>
          </tr>
          )}
        </tbody>
      </Table>
    </Container>
  )
};

export default ProjectUsersPage;
import React, { useEffect, useMemo, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet
} from "react-router-dom";
import LoginPage from "./components/pages/login/LoginPage";
import { initAdminConfig } from "./services/admin-config";
import 'bootstrap/dist/css/bootstrap.min.css';
import UsersPage from "./components/pages/users/UsersPage";
import CreateEditUserPage from "./components/pages/createEditUser/CreateEditUser";
import MainLayout from "./components/global/layouts/main/MainLayout";
import ProjectsPage from "./components/pages/projects/ProjectsPage";
import CreateEditProjectPage from "./components/pages/createEditProject/CreateEditProject";
import ProjectPage from "./components/pages/project/ProjectPage";
import ProjectLayout from "./components/global/layouts/project/ProjectLayout";
import CreateEditCompanyPage from "./components/pages/createEditCompany/CreateEditCompanyPage";
import CompaniesPage from "./components/pages/companies/CompaniesPage";
import CreateEditProjectUserPage from "./components/pages/createEditProjectUser/CreateEditProjectUserPage";
import ProjectUsersPage from "./components/pages/projectUsers/ProjectUsersPage";
import { AuthToken, StoreKey } from "@lookahead/core";
import { LocalStorageService } from "./services/store/local-storage";
import { AuthContext } from "./services/contexts/auth-context";
import CreateEditActivityPage from "./components/pages/createEditActivity/CreateEditActivityPage";
import ActivitiesPage from "./components/pages/activities/ActivitiesPage";

import "react-datepicker/dist/react-datepicker.css";
import 'rc-time-picker/assets/index.css';
import LocationGroupsPage from "./components/pages/locationGroups/LocationGroupsPage";

//initialize @lookahead/core configuration for admin user
initAdminConfig();

const App = () => {
  const [isReady, setIsReady] = useState(false);

  // logged in user context
  const [authToken, setAuthToken] = useState<AuthToken | undefined>(undefined);
  const authTokenProviderValue = useMemo(() => ({authToken, setAuthToken}), [authToken, setAuthToken]);

  useEffect(() => {
    LocalStorageService.getItem<any>(StoreKey.AUTH, AuthToken).subscribe({
      next: value => {
        setAuthToken(value);
      },
      complete: () => {
        setIsReady(true);
      }
    });
  }, []);

  const AppContexts = ({children}: any) => (
    <AuthContext.Provider value={authTokenProviderValue}>
      {children}
    </AuthContext.Provider>
  );

  if (!isReady) {
    return null;
  }

  return (
  <AppContexts>
  <Router>
    <Routes>
      <Route path='/' element={<MainLayout><ProjectsPage /></MainLayout>} />
      <Route path='/login' element={<LoginPage />} />
      <Route element={<MainLayout><Outlet /></MainLayout>}>
        <Route path='/projects/new' element={<CreateEditProjectPage />} />
        <Route path='/users' element={<UsersPage />} />
        <Route path='/users/new' element={<CreateEditUserPage />} />
        <Route path='/users/:userId/edit' element={<CreateEditUserPage />} />
        <Route path='projects/:projectId' element={<ProjectLayout />}>
          <Route path='' element={<ProjectPage />} />
          <Route path='edit' element={<CreateEditProjectPage />} />
          <Route path='companies' element={<CompaniesPage />}/>
          <Route path='companies/new' element={<CreateEditCompanyPage />}/>
          <Route path='companies/:companyId/edit' element={<CreateEditCompanyPage />} />
          <Route path='project-users' element={<ProjectUsersPage />}/>
          <Route path='project-users/new' element={<CreateEditProjectUserPage />}/>
          <Route path='project-users/:userId/edit' element={<CreateEditProjectUserPage />} />
          <Route path='activities' element={<ActivitiesPage />}/>
          <Route path='location-groups' element={<LocationGroupsPage/>}/>
          <Route path='activities/new' element={<CreateEditActivityPage />}/>
          <Route path='activities/:activityId/edit' element={<CreateEditActivityPage />} />
        </Route>
      </Route>
    </Routes>
  </Router>
  </AppContexts>
  );
}

export default App;

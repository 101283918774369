import { FormControl, FormControlProps } from "react-bootstrap";
import styled from "styled-components";
import { Theme } from "../../styles/theme";

export type AppFormControlProps = {
  error?: boolean;
} & FormControlProps

const AppFormControl = styled(FormControl)<AppFormControlProps>`
  ${props => {
    if (props.error) return `background-color: ${Theme.colors.red50}`;
  }};
  ${props => props.error && `
    border-color: ${Theme.colors.red100};
  `}
  ::placeholder {
    color: ${props => {
      if (props.error) return Theme.colors.red100;
      return Theme.colors.gray250;
    }};
    opacity: 1;
  }
`;
export default AppFormControl;
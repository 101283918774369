import Compressor from 'compressorjs';

const resizeImage = async (file: File, quality?: number): Promise<Blob> => {
  return new Promise(resolve => {
    quality ??= 0.5;
    new Compressor(file, {
      quality,
      success(result) {
        resolve(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
  });
};

const fileUtils = {
  resizeImage
};

export default fileUtils;
import { ProjectLite, ProjectService } from "@lookahead/core";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';

type ContextType = { project: ProjectLite | undefined };

const ProjectLayout = () => {
  const [project, setProject] = useState<ProjectLite | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { projectId } = useParams();
  const [projectPath, setProjectPath] = useState('');

  useEffect(() => {
    if (!projectId) {
      return;
    }
    ProjectService.getProject(Number.parseInt(projectId)).subscribe({
      next: project => {
        setProject(project);
        setIsLoading(false);
      },
      error: () =>{
        setIsLoading(false);
        setHasError(true);
      }
    });
    setProjectPath(`/projects/${projectId}`);
  }, [projectId]);

  if (isLoading) {
    return <p>Loading Project...</p>
  }
  if (hasError) {
    return <Alert variant="danger">404 Error: Project with id {projectId} not found.</Alert>
  }
  return (
    <>
   <Navbar bg="dark" variant="dark" sticky="top">
      <Container>
        <LinkContainer to={`${projectPath}/edit`}>
          <Navbar.Brand>{project.name}</Navbar.Brand>
        </LinkContainer>
        <Nav className="me-auto" defaultActiveKey="/projects">
          <LinkContainer to={`${projectPath}/companies`}>
            <Nav.Link>Companies</Nav.Link>
          </LinkContainer>
          <LinkContainer to={`${projectPath}/project-users`}>
            <Nav.Link>Project Users</Nav.Link>
          </LinkContainer>
          <LinkContainer to={`${projectPath}/activities`}>
            <Nav.Link>Activities</Nav.Link>
          </LinkContainer>
          <LinkContainer to={`${projectPath}/location-groups`}>
            <Nav.Link>Location Groups</Nav.Link>
          </LinkContainer>
        </Nav>
      </Container>
    </Navbar>
    <Outlet context={{ project }} />
    </>
  );
}

export const useProject = () => {
  return useOutletContext<ContextType>();
}
export default ProjectLayout;
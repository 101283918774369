import { ProjectLite, ProjectService } from "@lookahead/core";
import { useEffect, useState, useCallback } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { debounce } from 'lodash';
import { useNavigate } from "react-router-dom";

const ProjectsPage = () => {
  const [projects, setProjects] = useState<ProjectLite[]>([]);
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();

  const handleDebounce = (value: string) => {
    ProjectService.getAllProjects(value).subscribe({
      next: response => {
        setProjects(response);
      },
      error: err => {
        console.log(err);
      }
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceQuery = useCallback(debounce(handleDebounce, 500), []);
  const handleQueryChange = (event: any) => {
      setQuery(event.target.value);
      debounceQuery(event.target.value);
  };

  useEffect(() => {
    ProjectService.getAllProjects().subscribe({
      next: response => {
        setProjects(response);
      },
      error: err => {
        console.log(err);
      }
    })
  }, []);
  return (
    <Container>
      <Row style={{marginTop: 24, marginBottom: 12, justifyContent: 'space-between', alignItems: 'flex-end'}}>
        <Col><h2 style={{marginBottom: 0}}>Projects</h2></Col>
        <Col style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        <Button size='sm' style={{marginRight: 8}} onClick={() => navigate('/projects/new')}>New Project</Button>
        <input placeholder="Search" value={query} onChange={handleQueryChange}/>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Address</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project, i) =>
          <tr key={i}>
            <td>{project.id}</td>
            <td>{project.name}</td>
            <td>{project.address1} {project.address2} {project.city} {project.state} {project.zipcode}</td>
            <td>
              <Button variant="outline-secondary" size="sm" onClick={() => navigate(`/projects/${project.id}/edit`)}>Edit</Button>
            </td>
          </tr>
          )}
        </tbody>
      </Table>
    </Container>
  )
};

export default ProjectsPage;
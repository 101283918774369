import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import * as Yup from 'yup';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from "react";
import AppFormControl from '../../global/form-control/AppFormControl';
import AppErrorMessage from '../../global/error-message/AppErrorMessage';
import { CompanyLite, CompanyService, Activity, ActivityService, dateUtils, WorkingCalendar, WorkingCalendarService, DayLabel, ActivityLite, CompanyUserSelection, activityUtils, LocationGroup } from '@lookahead/core';
import Alert from 'react-bootstrap/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useProject } from '../../global/layouts/project/ProjectLayout';
import DatePicker from "react-datepicker";
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import SelectLocationGroupModal from '../../modals/selectLocationGroup/SelectLocationGroupModal';
import { Theme } from '../../styles/theme';

const CreateEditActivityPage = () => {
  const { activityId } = useParams();
  const [companyOptions, setCompanyOptions] = useState<CompanyLite[]>([]);
  const [companiesLoading, setCompaniesLoading] = useState(false);
  const [predecessorLoading, setPredecessorLoading] = useState(false);
  const [predecessorOptions, setPredecessorOptions] = useState<ActivityLite[]>([]);
  const [workingCalendars, setWorkingCalendars] = useState<WorkingCalendar[]>([]);
  const [showLocationGroupModal, setShowLocationGroupModal] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<LocationGroup[]>([]);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    start_time_minutes: Yup.number().nullable(),
    end_time_minutes: Yup.number().nullable(),
    planned_start_date: Yup.date().required(),
    planned_end_date: Yup.date().required(),
    is_critical: Yup.boolean().nullable(),
    location_group_id: Yup.number().nullable(),
    predecessor: Yup.object().nullable(),
    is_all_day: Yup.boolean().nullable(),
    working_calendar_id: Yup.number().required(),
    company: Yup.object().shape({
      id: Yup.number().required(),
      name: Yup.string().required()
    }).required('Company Assignment Required'),
    project_user_ids: Yup.array().of(Yup.number())
  });
  const formOptions = { resolver: yupResolver(validationSchema), defaultValues: {is_all_day: true, is_critical: false } };
  const { register, control, handleSubmit, formState, reset, getValues, setValue, watch } = useForm(formOptions);
  const { errors } = formState;
  const watchIsAllDay = watch("is_all_day", true);
  const watchPredecessor = watch("predecessor", undefined);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const isEdit = () => activityId !== undefined;
  const { project } = useProject();

  const onSearchCompanies = (query: string) => {
    setCompaniesLoading(true);
    CompanyService.getCompaniesAdmin(project.id, query).subscribe({
      next: companies => {
        setCompanyOptions(companies);
        setCompaniesLoading(false);
      },
      error: _ => {
        setCompanyOptions([]);
        setCompaniesLoading(false);
      }
    });
  };

  const onSearchPredecessor = (query: string) => {
    setPredecessorLoading(true);
    ActivityService.getPublishedActivitiesAdmin(project.id, {query}).subscribe({
      next: predecessors => {
        setPredecessorLoading(false);
        setPredecessorOptions(predecessors);
      },
      error: _ => {
        setPredecessorLoading(false);
        setPredecessorOptions([]);
      }
    });
  };

  const onSubmit = (data: any) => {
    if (isSubmitting) {
      return;
    }
    setErrorMessage(undefined);
    setIsSubmitting(true);
    const newActivity = new Activity();
    newActivity.name = data.name;
    newActivity.start_time_minutes = data.start_time_minutes;
    newActivity.end_time_minutes = data.end_time_minutes;
    newActivity.planned_start_date = data.planned_start_date;
    newActivity.planned_end_date = data.planned_end_date;
    newActivity.is_critical = !!data.is_critical;
    newActivity.location_group_id = selectedLocations.length > 0 ? selectedLocations[selectedLocations.length - 1].id : undefined;
    // newActivity.predecessor_activity = data.predecessor;
    newActivity.is_all_day = !!data.is_all_day;
    newActivity.working_calendar_id = data.working_calendar_id;
    newActivity.company = data.company;
  const assignments: CompanyUserSelection[] = [];
    if (data.company) {
      assignments.push({company: data.company, project_user_ids: []});
    }
    let request = ActivityService.createPublishedActivityAdmin(project.id, newActivity, assignments, []);
    if (isEdit()) {
      request = ActivityService.updateActivityAdmin(project.id, Number.parseInt(activityId), newActivity, assignments[0], []);
    }
    request.subscribe({
      next: () => {
        setIsSubmitting(false);
        navigate(`/projects/${project.id}/activities`, {replace: true});
      },
      error: e => {
        setErrorMessage('Save Failed: Unable to save activity changes.');
        setIsSubmitting(false);
      }
    });
  };

  useEffect(() => {
    WorkingCalendarService.getWorkingCalendarsAdmin(project.id).subscribe({
      next: res => setWorkingCalendars(res)
    });
    if (!activityId) {
      return;
    }
    setErrorMessage(undefined);
    ActivityService.getActivityAdmin(project.id, Number.parseInt(activityId)).subscribe({
      next: activity => {
        setSelectedLocations(activity.location_groups);
        reset({
          name: activity.name,
          is_critical: activity.is_critical,
          start_time_minutes: activity.start_time_minutes,
          end_time_minutes: activity.end_time_minutes,
          planned_start_date: activity.planned_start_date,
          planned_end_date: activity.planned_end_date,
          location_group_id: activity.location_group_id,
          // predecessor: activity.predecessor_activity,
          is_all_day: activity.is_all_day,
          working_calendar_id: activity.working_calendar?.id,
          company: activity.company
        });
      }
    });
  }, [project, activityId, reset]);

  useEffect(() => {
    const predecessor = getValues('predecessor');
    if (predecessor) {
      const endDate = predecessor.actual_end_date || predecessor.projected_end_date;
      setValue('planned_start_date', moment(endDate).add(1, 'day').toDate())
    }
  }, [watchPredecessor, getValues, setValue]);

  return (
    <Container style={{marginTop: 16}}>
      <h2>{isEdit() ? 'Edit' : 'New'} Activity</h2>
      {errorMessage !== undefined && <Alert variant='danger'>{errorMessage}</Alert>}
      {!errorMessage?.includes('404') &&
      <Form onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))} style={{marginBottom: 48}}>
        <>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <AppFormControl {...register('name')} placeholder="" error={errors.name || undefined} />
          <AppErrorMessage name='name' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="endDate">
          <Form.Label>Location Group</Form.Label>
          <div>
            <div style={{border: `1px solid ${Theme.colors.gray100}`, display: 'inline-block', borderRadius: 4, padding: 6, cursor: 'pointer'}}
              onClick={() => setShowLocationGroupModal(true)}>
                {selectedLocations.length === 0 ?
                project.name :
                selectedLocations.map((l, i) => <div key={i} style={{paddingLeft: i * 6}}>{l.name}</div>)
                }
            </div>
          </div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="is_critical">
          <Form.Check {...register('is_critical')} id="is_critical" label='Critical Path' />
        </Form.Group>
        <Form.Group className="mb-3" controlId="is_all_day">
          <Form.Check {...register('is_all_day')} id="is_all_day" label='Is All Day' />
        </Form.Group>
        {!watchIsAllDay &&
        <>
        <Form.Group className="mb-3" controlId="startTime">
          <Form.Label>Start Time </Form.Label>
          <Controller control={control} name='start_time_minutes' render={({field}) =>
            <TimePicker value={field.value !== undefined ? moment(dateUtils.getTimeFromMinutes(field.value)) : moment()} onChange={moment => field.onChange(dateUtils.getMinutesFromMidnight(moment.toDate()))} use12Hours={true} showSecond={false} minuteStep={30}/>
          }/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="endTime">
          <Form.Label>End Time </Form.Label>
          <Controller control={control} name='end_time_minutes' render={({field}) =>
            <TimePicker value={field.value !== undefined ? moment(dateUtils.getTimeFromMinutes(field.value)) : moment()} onChange={moment => field.onChange(dateUtils.getMinutesFromMidnight(moment.toDate()))} use12Hours={true} showSecond={false} minuteStep={30}/>
          }/>
        </Form.Group>
        </>
        }
        </>
        <Form.Group className="mb-3">
          <Form.Label>Predecessor</Form.Label>
          {false && <Controller control={control} name='predecessor' render={({field}) =>
            <AsyncTypeahead
            id="predecessor-typeahead"
            isLoading={predecessorLoading}
            labelKey={option => {
              const a: ActivityLite = option as ActivityLite;
              return a.name + ' ' + (a.location_group_id ? `(${activityUtils.locationStr(a)})`: undefined);
            }}
            placeholder={field.value ? (field.value?.name + ' ' + (field.value?.location_group_id ? `(${activityUtils.locationStr(field.value)})`: undefined)) : ''}
            onChange={(c: ActivityLite[]) => c.length === 1 ? field.onChange(c[0]) : field.onChange(undefined)}
            onSearch={(query) => onSearchPredecessor(query)}
            options={predecessorOptions}
          />
          }/>
          }
          <AppErrorMessage name='company' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="startDate">
          <Form.Label>Start Date</Form.Label>

          <Controller control={control} name='planned_start_date' render={({field}) =>
            watchPredecessor ? <div>{dateUtils.format(field.value, 'MM/DD/YYYY')}</div> :
            <DatePicker selected={field.value} onChange={field.onChange} />
          }/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="endDate">
          <Form.Label>End Date</Form.Label>
          <Controller control={control} name='planned_end_date' render={({field}) =>
            <DatePicker selected={field.value} onChange={field.onChange} />
          }/>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Assigned Company</Form.Label>
          <Controller control={control} name='company' render={({field}) =>
            isEdit() ? <div>{field.value?.name}</div> :
            <AsyncTypeahead
              id="company-typeahead"
              isLoading={companiesLoading}
              labelKey="name"
              placeholder={field.value?.name}
              onChange={(c: CompanyLite[]) => c.length === 1 ? field.onChange({id: c[0].id, name: c[0].name}) : field.onChange({id: undefined, name: undefined})}
              onSearch={(query) => onSearchCompanies(query)}
              options={companyOptions}
            />
          }/>
          <AppErrorMessage name='company' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="role">
          <Form.Label>Working Calendar</Form.Label>
          {workingCalendars.map((w, i) =>
          <Controller key={i} control={control} name='working_calendar_id' render={({field}) =>
            <Form.Check id={"wc" + i} value={w.id} type="radio"
              label={`${DayLabel[w.start_day]} - ${DayLabel[w.end_day]} ${dateUtils.getTimeString(w.day_start_time)} - ${dateUtils.getTimeString(w.day_end_time)} ${w.is_default ? 'Default' : ''}`}
              onChange={() => field.onChange(w.id)}
              checked={field.value === w.id} />
          }/>
          )}
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      }
      <SelectLocationGroupModal project={project} show={showLocationGroupModal}
        onClose={() => setShowLocationGroupModal(false)}
        onSave={locations => {setSelectedLocations(locations)}}
        selectedLocations={selectedLocations} />
    </Container>
  );
};
export default CreateEditActivityPage;
import { User, UserService } from "@lookahead/core";
import { useEffect, useState, useCallback } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { debounce } from 'lodash';
import { useNavigate } from "react-router-dom";
import { switchMap } from "rxjs";

const UsersPage = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();

  const handleDebounce = (value: string) => {
    UserService.getUsers(value).subscribe({
      next: response => {
        setUsers(response);
      },
      error: err => {
        console.log(err);
      }
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceQuery = useCallback(debounce(handleDebounce, 500), []);
  const handleQueryChange = (event: any) => {
      setQuery(event.target.value);
      debounceQuery(event.target.value);
  };

  const onSendInvite = (userId: number) => {
    UserService.sendInvite(userId).pipe(switchMap(() => UserService.getUsers(query))).subscribe({
      next: (response) => {
        setUsers(response);
        alert('Invite Sent!')
      },
      error: () => alert('Unable to send invitation. Check phone number and try again.')
    });
  }

  useEffect(() => {
    UserService.getUsers().subscribe({
      next: response => {
        setUsers(response);
      },
      error: err => {
        console.log(err);
      }
    })
  }, []);
  return (
    <Container>
      <Row style={{marginTop: 24, marginBottom: 12, justifyContent: 'space-between', alignItems: 'flex-end'}}>
        <Col><h2 style={{marginBottom: 0}}>Users</h2></Col>
        <Col style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        <Button size='sm' style={{marginRight: 8}} onClick={() => navigate('/users/new')}>New User</Button>
        <input placeholder="Search" value={query} onChange={handleQueryChange}/>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Position</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Accepted Invite?</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, i) =>
          <tr key={i}>
            <td>{user.id}</td>
            <td>{user.fullName}</td>
            <td>{user.position}</td>
            <td>{user.phone}</td>
            <td>{user.email}</td>
            <td>{user.accepted_invite ? 'Yes' : 'No'}</td>
            <td>
              <Button variant="outline-secondary" size="sm" onClick={() => navigate(`/users/${user.id}/edit`)}>Edit</Button>
              {!user.accepted_invite && user.invited &&
              <Button variant="outline-success" size="sm" onClick={() => onSendInvite(user.id)} style={{marginLeft: 4}}>Resend Invite</Button>
              }
              {!user.accepted_invite && !user.invited &&
              <Button variant="success" size="sm" onClick={() => onSendInvite(user.id)} style={{marginLeft: 4}}>Send Invite</Button>
              }
            </td>
          </tr>
          )}
        </tbody>
      </Table>
    </Container>
  )
};

export default UsersPage;
import { plainToInstance } from 'class-transformer';
import { map } from 'rxjs';
import { Company } from '../../models/Company.js';
import { UserLite } from '../../models/User.js';
import { ApiService } from './api-base.js';
const createCompanyParams = (company) => {
    const body = {
        company: {
            name: company.name,
            trade: company.trade,
            profile_picture: company.profile_picture_upload_file
        }
    };
    // this is returning 'undefined'? TODO fix this
    if (!body.company.profile_picture) {
        delete body.company.profile_picture;
    }
    return body;
};
export const CompanyService = {
    getCompanies: (projectId, query) => ApiService.getWithQuery(`/projects/${projectId}/companies`, query)
        .pipe(map((response) => plainToInstance(Company, response))),
    getCompaniesAdmin: (projectId, query) => ApiService.getWithQuery(`/projects/${projectId}/admin/companies`, query)
        .pipe(map((response) => plainToInstance(Company, response))),
    getCompany: (projectId, companyId) => ApiService.get(`/projects/${projectId}/companies/${companyId}`)
        .pipe(map((response) => plainToInstance(Company, response))),
    getCompanyAdmin: (projectId, companyId) => ApiService.get(`/projects/${projectId}/admin/companies/${companyId}`)
        .pipe(map((response) => plainToInstance(Company, response))),
    getCompanyUsers: (projectId, companyId) => ApiService.get(`/projects/${projectId}/companies/${companyId}/users`)
        .pipe(map((response) => plainToInstance(UserLite, response))),
    createCompany: (projectId, company) => ApiService.post(`/projects/${projectId}/companies`, createCompanyParams(company), { formData: true, includeUploadProgress: true })
        .pipe(map((response) => {
        return Object.assign(Object.assign({}, response), { response: response.response ? plainToInstance(Company, response.response) : null });
    })),
    createCompanyAdmin: (projectId, company) => ApiService.post(`/projects/${projectId}/admin/companies`, createCompanyParams(company), { formData: true, includeUploadProgress: true })
        .pipe(map((response) => {
        return Object.assign(Object.assign({}, response), { response: response.response ? plainToInstance(Company, response.response) : null });
    })),
    updateCompany: (projectId, companyId, company) => ApiService.put(`/projects/${projectId}/companies/${companyId}`, createCompanyParams(company), { formData: true, includeUploadProgress: true })
        .pipe(map((response) => {
        return Object.assign(Object.assign({}, response), { response: response.response ? plainToInstance(Company, response.response) : null });
    })),
    updateCompanyAdmin: (projectId, companyId, company) => ApiService.put(`/projects/${projectId}/admin/companies/${companyId}`, createCompanyParams(company), { formData: true, includeUploadProgress: true })
        .pipe(map((response) => {
        return Object.assign(Object.assign({}, response), { response: response.response ? plainToInstance(Company, response.response) : null });
    })),
    deleteProfilePicture: (projectId, companyId) => ApiService.delete(`/projects/${projectId}/companies/${companyId}/profile_picture`)
};

import { Navigate } from "react-router-dom";
import { useAuth } from "../../services/contexts/auth-context";

export const RequireAuth = ({ children }: any) => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    // user is not authenticated
    return <Navigate to="/login" replace={true} />;
  }
  return children;
};
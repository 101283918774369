import { Container } from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { RequireAuth } from "../../RequireAuth";

const MainLayout = ({children}: any) => {
  return (
    <RequireAuth>
    <Navbar bg="light" variant="light" sticky="top">
      <Container>
        <LinkContainer to='/'>
          <Navbar.Brand>Lookahead</Navbar.Brand>
        </LinkContainer>
        <Nav className="me-auto" defaultActiveKey="/projects">
          <LinkContainer to='/'>
            <Nav.Link>Projects</Nav.Link>
          </LinkContainer>
          <LinkContainer to='/users'>
            <Nav.Link>App Users</Nav.Link>
          </LinkContainer>
        </Nav>
        <Nav className="ms-auto">
          <LinkContainer to='/login'>
            <Nav.Link>Logout</Nav.Link>
          </LinkContainer>
        </Nav>
      </Container>
    </Navbar>
    <div>
      {children}
    </div>
    </RequireAuth>
  )
};

export default MainLayout;
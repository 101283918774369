import { Theme } from "../../styles/theme";

export type AppErrorMessageProps = {
  name: string;
  errors?: any;
};

const AppErrorMessage = ({name, errors}: AppErrorMessageProps) => {
  if (!errors || !errors[name]) {
    return null;
  }
  return (
    <p style={{marginTop: 8, color: Theme.colors.red100, fontSize: Theme.fontSize.sm }}>
      {errors[name].message}
    </p>
  );
}

export default AppErrorMessage;
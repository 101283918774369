import { plainToInstance } from 'class-transformer';
import { map } from 'rxjs';
import { WorkingCalendar } from '../../models/WorkingCalendar.js';
import { ApiService } from './api-base.js';
export const WorkingCalendarService = {
    getWorkingCalendars: (projectId) => ApiService.get(`/projects/${projectId}/working_calendars`)
        .pipe(map((response) => plainToInstance(WorkingCalendar, response))),
    getWorkingCalendarsAdmin: (projectId) => ApiService.get(`/projects/${projectId}/admin/working_calendars`)
        .pipe(map((response) => plainToInstance(WorkingCalendar, response))),
    getWorkingCalendar: (projectId, workingCalendarId) => ApiService.get(`/projects/${projectId}/working_calendars/${workingCalendarId}`)
        .pipe(map((response) => plainToInstance(WorkingCalendar, response))),
    getDefaultWorkingCalendar: (projectId) => ApiService.get(`/projects/${projectId}/default_working_calendar`)
        .pipe(map((response) => plainToInstance(WorkingCalendar, response))),
    getWorkingCalendarAdmin: (projectId, workingCalendarId) => ApiService.get(`/projects/${projectId}/admin/working_calendars/${workingCalendarId}`)
        .pipe(map((response) => plainToInstance(WorkingCalendar, response))),
    createWorkingCalendar: (projectId, params) => ApiService.post(`/projects/${projectId}/working_calendars`, { working_calendar: params })
        .pipe(map((response) => plainToInstance(WorkingCalendar, response))),
    updateWorkingCalendar: (projectId, workingCalendarId, params) => ApiService.put(`/projects/${projectId}/working_calendars/${workingCalendarId}`, { working_calendar: params })
        .pipe(map((response) => plainToInstance(WorkingCalendar, response))),
    deleteWorkingCalendar: (projectId, workingCalendarId) => ApiService.delete(`/projects/${projectId}/working_calendars/${workingCalendarId}`),
};

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from "react";
import AppFormControl from '../../global/form-control/AppFormControl';
import AppErrorMessage from '../../global/error-message/AppErrorMessage';
import { User, UserService, AppRole } from '@lookahead/core';
import Alert from 'react-bootstrap/Alert';
import { useNavigate, useParams } from 'react-router-dom';

const CreateEditUserPage = () => {
  const { userId } = useParams();
  const validationSchema = Yup.object().shape({
    app_role: Yup.string().nullable()
                    .default(AppRole.USER),
    first_name: Yup.string()
                  .required('First Name is required'),
    last_name: Yup.string()
                  .required('First Name is required'),
    position: Yup.string().optional().nullable(),
    email: Yup.string().optional().nullable(),
    phone: Yup.string().required('Phone Number is required'),
    send_invite: Yup.boolean().default(true)
  });
  const formOptions = { resolver: yupResolver(validationSchema), defaultValues: {send_invite: false, app_role: AppRole.USER} };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const isEdit = () => userId !== undefined;

  const onSubmit = (data: any) => {
    if (isSubmitting) {
      return;
    }
    setErrorMessage(undefined);
    setIsSubmitting(true);
    const newUser = new User();
    newUser.first_name = data.first_name;
    newUser.last_name = data.last_name;
    newUser.position = data.position;
    newUser.email = data.email;
    newUser.phone = data.phone;
    newUser.app_role = data.app_role;

    let request = UserService.createUser(newUser, data.send_invite);
    if (isEdit()) {
      request = UserService.updateUser(Number.parseInt(userId), newUser)
    }
    request.subscribe({
      next: () => {
        setIsSubmitting(false);
        navigate('/users', {replace: true});
      },
      error: () => {
        setErrorMessage('Save Failed: Unable to save user changes.');
        setIsSubmitting(false);
      }
    });
  };

  useEffect(() => {
    if (!userId) {
      return;
    }
    setErrorMessage(undefined);
    UserService.getUser(Number.parseInt(userId)).subscribe({
      next: user => {
        reset({
          first_name: user.first_name,
          last_name: user.last_name,
          position: user.position,
          email: user.email,
          phone: user.phone,
          app_role: user.app_role
        });
      },
      error: () => {
        setErrorMessage('404 Error: Unable to find user with id '  + userId);
      }
    });
  }, [userId, reset]);

  return (
    <Container>
      <h2>{isEdit() ? 'Edit' : 'New'} User</h2>
      {errorMessage !== undefined && <Alert variant='danger'>{errorMessage}</Alert>}
      {!errorMessage?.includes('404') &&
      <Form onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))}>
        <Form.Group className="mb-3" controlId="first_name">
          <Form.Label>First Name</Form.Label>
          <AppFormControl {...register('first_name')} placeholder="" error={errors.first_name || undefined} />
          <AppErrorMessage name='first_name' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="last_name">
          <Form.Label>Last Name</Form.Label>
          <AppFormControl {...register('last_name')} placeholder="" error={errors.last_name || undefined} />
          <AppErrorMessage name='last_name' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="position">
          <Form.Label>Position</Form.Label>
          <AppFormControl {...register('position')} placeholder="" error={errors.position || undefined} />
          <AppErrorMessage name='position' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="phone">
          <Form.Label>Phone Number</Form.Label>
          <AppFormControl {...register('phone')} placeholder="" error={errors.phone || undefined} />
          <AppErrorMessage name='phone' errors={errors}/>
          <p>Used for user login. Must be unique.</p>
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <AppFormControl {...register('email')} placeholder="" error={errors.email || undefined} />
          <AppErrorMessage name='email' errors={errors}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="role">
          <Form.Label>App Role</Form.Label>
          <Form.Check {...register('app_role')} id="a-role" value={AppRole.USER} type="radio" label='User' />
          <Form.Check {...register('app_role')} id="m-role"value={AppRole.APP_ADMIN} type="radio" label='App Admin (used to access admin panel)'/>
          <AppErrorMessage name='app_role' errors={errors}/>
        </Form.Group>
        {!isEdit() &&
        <Form.Group className="mb-3" controlId="role">
          <Form.Check {...register('send_invite')} id="send_invite" label='Send Invitation Text' />
        </Form.Group>
        }
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      }
    </Container>
  );
};
export default CreateEditUserPage;